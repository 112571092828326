@import 'colors.scss';

.Task {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-bottom: 0.2em;

  &.completed {
    text-decoration: line-through;
    font-style: italic;
    color: gray;
  }

  > img {
    height: 50%;
  }
}