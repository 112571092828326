@import 'colors.scss';

.TaskList {
  display: flex;
  flex-direction: column;
  background-color: $list_color;
  padding: 0.5em;
  border: 0.2em solid $accent_color;
  border-radius: 0.8em;
  margin-bottom: 0.5em;


  
  > .TaskList-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 30px;

    > h3 {

      > span {
        opacity: 0.4;
        font-size: 0.8em;
      }
    }
    

    > img {
      visibility: hidden;
      height: 70%;

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      > img {
        visibility: visible;
      }
    }
  }

  > .Task-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
  }
}