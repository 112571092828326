@import 'colors.scss';

Header {
  position: absolute;
  display: flex;
  width: 100%;
  left: 0;
  background-color: $header_color;
  padding-right: 2em;
  padding-left: 2em;

  &.second {
    pointer-events: none;
    background-color: transparent;
    justify-content: center;

    * {
      pointer-events: auto;
    }
  }

  img {
    display: block;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
}