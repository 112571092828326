@import 'colors.scss';
// FIXME: All groups same width
.TaskGroup {
  display: flex;
  flex-direction: column;
  background-color: $group_color;
  padding: 0.5em;
  border: 0.2em solid $accent_color;
  border-radius: 0.8em;
  margin-right: 0.5em;
  flex: 1 1 0px;
  font-size: 1em;

  > .TaskGroup-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;

    > img {
      visibility: hidden;
      height: 70%;

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      > img {
        visibility: visible;
      }
    }
  }

  > .TaskList-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
  }
}