@import 'colors.scss';

.Add, .Edit {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 1.2em;

  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);

    &:hover {
      cursor: pointer;
    }
  }

  &-main {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $background_color;
    border: 0.2em solid $accent_color;
    border-radius: 0.8em;
    padding: 2em;
    min-width: 30%;
    
    &-inputs {
      display: flex;
      flex-direction: column;
      margin-bottom: 2em;
      
      > * {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.5em;

        > label {
          margin-right: 2em;
        }

        > input {
          background-color: rgba(0, 0, 0, 0);
          box-shadow: none;
          border: none;
          border-bottom: 0.2em solid $accent_color;
          font-size: 1em;
        }

        > select {
          background-color: rgba(0, 0, 0, 0);
          box-shadow: none;
          border: none;
          border-bottom: 0.2em solid $accent_color;
          font-size: 1em;
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      &-delete {
        color: rgba(220, 20, 20);
      }

      > * {

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}